import React from 'react'
import PropTypes from 'prop-types'

import { isInputDateSupported } from 'utils'

import { BaseHtmlValidationInput } from './base-html-validation-input'
import { FormattedInput } from './formatted-input'

const DATE_SUPPORTED = isInputDateSupported()

class NativeDateInput extends BaseHtmlValidationInput {
  static propTypes = {
    autoComplete: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    validation: PropTypes.func,
    validationMessages: PropTypes.exact({
      valueMissing: PropTypes.string,
      badInput: PropTypes.string,
      rangeOverflow: PropTypes.string,
      rangeUnderflow: PropTypes.string,
      stepMismatch: PropTypes.string,
    }),
  }
  static defaultProps = {
    autoComplete: 'off',
    validationMessages: {},
  }

  handleBlur = (e) => {
    this.validate()
    this.props.onBlur && this.props.onBlur(e)
  }

  handleChange = () => {
    // update the DOM element validity
    this.resetCustomValidity()
    // let the parent form knows about changes
    this.broadcastUpdates()
  }

  /**
   * @override
   */
  setValue(value = null) {
    if (!this.element) return
    this.element.value = value
    this.resetCustomValidity()
    this.broadcastUpdates()
  }

  /**
   * @override
   */
  getValue() {
    if (!this.element) return undefined
    if (!this.element.value) return null

    const date =
      this.element.valueAsDate === undefined
        ? new Date(this.element.value)
        : this.element.valueAsDate

    return date ? date.toISOString().replace(/T.*$/, '') : null
  }

  render() {
    const { validation, validationMessages, validationMessage, ...props } = this.props

    return (
      <input
        data-lpignore="true"
        {...props}
        type="date"
        ref={this.setElement}
        id={props.id || props.name}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
      />
    )
  }
}

class PseudoDateInput extends FormattedInput {
  static defaultProps = {
    placeholder: 'mm/dd/yyyy',
    options: {
      date: true,
      datePattern: ['m', 'd', 'Y'],
    },
  }

  getValue() {
    const value =
      this.cleaveInstance.getISOFormatDate() || this.cleaveInstance.getRawValue() || null
    return value
  }

  getValidationMessage() {
    const value = this.getValue()

    const { required, min, max } = this.props
    if (value === null) {
      return required ? 'Required' : null
    }
    const date = new Date(value)
    if (isNaN(date.valueOf())) {
      return 'Invalid date'
    }
    if (min && date < new Date(min)) {
      return `Must be greater than or equals to ${min}`
    }
    if (max && date > new Date(max)) {
      return `Must be lesser than or equals to ${max}`
    }
    return this.checkCustomValidity()
  }

  getNormalizedValue(date) {
    if (!date) return null
    if (date instanceof Date) {
      return formatDate(date)
    }
    if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}/.test(date)) {
      return formatDate(new Date(date))
    }
    return null
  }
}

export const DateInput = DATE_SUPPORTED ? NativeDateInput : PseudoDateInput

const formatDate = (date) =>
  date instanceof Date
    ? //Cleave needs format '01/01/2000' '1/01/2000' doesn't work correctly
      date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
    : null

export const formatAndNormalizeDate = (date, options) => {
  let parsedDate
  if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}/.test(date)) {
    parsedDate = new Date(date)
  } else if (date instanceof Date) {
    parsedDate = date
  } else {
    return null
  }

  const defaultOptions = {
    locale: 'en-US',
    format: {
      timeZone: 'UTC',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    },
  }

  const { locale, format } = { ...defaultOptions, ...options }

  return parsedDate.toLocaleDateString(locale, format)
}
